import { lazy } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Breadcrumbs, TabBar } from 'components'
import { Tabs } from 'components/Tabs'

import { TabData } from 'components/Tabs/types'

import styles from './CustomerManagementTabs.module.scss'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { ReactComponent as ShopIcon } from 'assets/svg/shop.svg'
import { ReactComponent as Icon } from 'assets/svg/exclamationMessageBlue.svg'
import { useCustomerContext } from './CustomerProvider'

import { paths } from 'routes'

const History = lazy(() => import('../History/History'))
const Patrimony = lazy(() => import('../Patrimonies/Patrimonies'))
const Contacts = lazy(() => import('../Contacts'))
const Reports = lazy(() => import('../Reports'))
const Central = lazy(() => import('../Central'))
const OfficeHoursList = lazy(
  () => import('domains/customer/screens/OfficeHour/screens/List'),
)
const Patrol = lazy(() => import('domains/Patrol/List'))
const Customer = lazy(() => import('../GeneralInfo/GeneralInfo'))
const Equipments = lazy(() => import('../Equipments'))

const tabData: TabData[] = [
  {
    id: '0',
    label: 'Horários de funcionamento',
    icon: <ShopIcon />,
    content: <OfficeHoursList />,
    path: 'officeHours',
  },
  {
    id: '1',
    label: 'Horários de ocorrências periódicas',
    icon: <Icon />,
    content: <Patrol />,
    path: 'patrol',
  },
]

export const CustomerManagement = () => {
  const [search, setSearch] = useSearchParams('client')
  const { customer } = useCustomerContext()

  const { clearAllLocalStorageFilters } = usePersistentFilters()

  const tab = search.get('tab') || 'client'
  return (
    <>
      <div className={styles.wrapperBreadcrumbs}>
        <Breadcrumbs
          items={[
            { title: 'Home', href: '/' },
            { title: 'Cliente', href: paths.account.list },
            { title: customer?.name || '', href: '' },
          ]}
        />
      </div>

      <TabBar>
        <TabBar.Tab
          id="client"
          idSelected={tab}
          label="Cliente"
          onClick={() => {
            setSearch({ tab: 'client' })

            clearAllLocalStorageFilters()
          }}
        >
          <Customer />
        </TabBar.Tab>
        <TabBar.Tab
          id="history"
          idSelected={tab}
          label="Histórico"
          onClick={() => {
            setSearch({ tab: 'history', page: '1' })

            clearAllLocalStorageFilters()
          }}
        >
          <History />
        </TabBar.Tab>
        <TabBar.Tab
          id="patrimony"
          idSelected={tab}
          label="Patrimônios"
          onClick={() => {
            setSearch({ tab: 'patrimony' })

            clearAllLocalStorageFilters()
          }}
        >
          <Patrimony />
        </TabBar.Tab>
        <TabBar.Tab
          id="central"
          idSelected={tab}
          label="Centrais"
          onClick={() => {
            setSearch({ tab: 'central' })

            clearAllLocalStorageFilters()
          }}
        >
          <Central />
        </TabBar.Tab>
        <TabBar.Tab
          id="contact"
          idSelected={tab}
          label="Contatos"
          onClick={() => {
            setSearch({ tab: 'contact', page: '1' })
            clearAllLocalStorageFilters()
          }}
        >
          <Contacts />
        </TabBar.Tab>
        <TabBar.Tab
          id="equipment"
          idSelected={tab}
          label="Equipamentos"
          onClick={() => {
            setSearch({ tab: 'equipment' })
          }}
        >
          <Equipments />
        </TabBar.Tab>
        <TabBar.Tab
          id="officeHour"
          idSelected={tab}
          label="Horários"
          onClick={() => {
            setSearch({ tab: 'officeHour', page: '1' })

            clearAllLocalStorageFilters()
          }}
        >
          <Tabs tabs={tabData} />
        </TabBar.Tab>
        <TabBar.Tab
          id="reports"
          idSelected={tab}
          label="Relatórios"
          onClick={() => {
            setSearch({ tab: 'reports' })

            clearAllLocalStorageFilters()
          }}
        >
          <Reports />
        </TabBar.Tab>
      </TabBar>
    </>
  )
}

export default CustomerManagement
